import {initTrustbars} from "../../../default/global/js/trustbar";
import {getChildrenClassForNestedItems, getChildrenClassForNestedItemsMain} from "../../../default/global/js/nav";
import {closeFilterBox, initFilter} from "../../../default/global/js/filter";
import {initActionClicks, initFormSubmitChanges, initFormSubmitClicks} from "../../../default/global/js/actionClick";
import {focusInputGroups} from "../../../default/global/js/checkInputGroups";
import {stickyHeader} from "../../../default/global/js/stickyheader";
import {initQuantitySpinners} from "../../../arivaGlobal/src/js/quantitySpinner";
import hoverintent, {removeOtherHoverintents} from "../../../default/global/js/hoverintent";
import {initSlideshows} from "../../../default/global/js/slideshow";
import {openFullscreenGallery} from "../../../default/global/js/fullscreengallery";
import {stickyBox} from "../../../default/global/js/stickyBox.js";
import {buildMobileMenu, hideMobileMenu, showMobileMenu} from "../../../arivaGlobal/src/js/mobilemenu";
import {excludeModal, triggerConfirmModalLink, triggerModal} from "../../../arivaGlobal/src/js/modal";
import {triggerBasketBtns, triggerUpdateBasketView} from "../../../default/global/js/basket";
import {initToggleSlide} from "../../../default/global/js/toggle";
import {initItemlistSlider} from "../../../default/global/js/itemListSlider";
import {generateVideoplayButton, setSrcVideos} from "../../../arivaGlobal/src/js/video";
import {initScrollbars} from "../../../arivaGlobal/src/js/scrollbar";
import {isTouchDevice} from "../../../default/global/js/touchDevice";
import {initCustomSelects} from "../../../default/global/js/customSelect";
import {initJumpmarks} from "../../../default/global/js/jumpmark";
import {initSearchItems} from "../../../default/global/js/searchItems";
import {initItemlistToggle} from "../../../default/global/js/itemlistToggle";
import {initPriceListGraduated} from "../../../default/global/js/priceListGraduated";
import {lottieLoader} from "../../../default/global/js/lottieLoader";
import {initFlashmessages} from "../../../default/global/js/flashmessages";
import {initPasswordFields} from "../../../default/global/js/password";
import {trimInputValues} from "../../../default/global/js/formValidator";
import {initSlidecontents, toggleMaxHeight} from "../../../arivaGlobal/src/js/slidecontent";
import {initPhoneCountryFields} from "../../../default/global/js/phoneCountryField";
import {initInputBody} from "../../../default/global/js/inputfile";
import {initAjaxCollectionFilter} from "../../../default/global/js/ajaxCollectionFilter";
import {initDatepicker} from "../../../default/global/js/datepicker";
import {countUp} from "../../../default/global/js/countUp";
import {startAnimationAfterDelay} from "./custom/loginBoxAnimation";
import {initImageGallery, initImageGalleryReference} from "../../../arivaGlobal/src/js/imageGallery";
import {testimonialSwiper} from "../../../arivaGlobal/src/js/testimonials";
import {collectionNewsSwiper} from "./custom/collectionNews";
import {initSparrechner} from "../../../arivaGlobal/src/js/sparrechner";
import {itemcardTabs} from "../../../arivaGlobal/src/js/itemcardTabs";
import {setMenuHeight, resetMenuHeight, setMenuHeightContent, resetMenuHeightContent} from "../../../arivaGlobal/src/js/setMenuHeight";
import {calculateDValue, calculateTotalWeight, calculateTrailerLoad} from "../../../arivaGlobal/src/js/calcDWert";
import {initVehicleSearchForm} from "../../../arivaGlobal/src/ts/vehicleSearch";
import {triggerFavoritesBtns} from "../../../arivaGlobal/src/js/favorites";
import {Tooltip} from "../../../default/global/js/tooltip";
import {sortAllSelectOptions} from "../../../arivaGlobal/src/js/sortFilterCollection";
import {initRecaptchaForms} from "../../../default/global/js/recaptchaForm";
import {handleAsideElement} from "../../../arivaGlobal/src/js/interferer";
import {initContentBoxSwiper} from "../../../arivaGlobal/src/js/contentBoxSwiper";
import Swiper from "swiper";
import {initSearchBar} from "../../../arivaGlobal/src/js/searchValidator";
import {handleOnboardingModal} from "../../../arivaGlobal/src/js/onboadingModal";


window.addEventListener("load", (event) => {
    const mobileMenu = document.querySelector('.mobileMenu');
    const mobileMenuToggle = document.getElementById("js-toggleNavigation");
    const overlay = document.getElementById("overlay");
    const IsTouchDevice = isTouchDevice();
    const stickyHeaderArea = document.querySelector('.headerMain');
    const megaMenuMain = document.querySelector('.mainnav .js-megamenu ul.level_2');
    const megaMenuMainContent = document.querySelector('.mainnav .js-megamenu_content ul.level_1');
    const totalWeightInput = document.getElementById('total-weight');
    const totalWeightInput2 = document.getElementById('total-weight-2');
    const trailerWeightInput2 = document.getElementById('trailer-weight-2');

    let breakPointSM = 1024;
    let sendFavoriteByJS = false;
    let sendItemToBasketByJS = false;
    let updateBasketByJS = false;

    if (typeof GLOBALS!== "undefined") {
        breakPointSM = GLOBALS.gridBreakpoints.sm;
        sendFavoriteByJS = GLOBALS.sendFavoriteByJS;
        //sendItemToBasketByJS = GLOBALS.sendItemToBasketByJS;
        updateBasketByJS = GLOBALS.updateBasketByJS;
    }

    if (mobileMenu && mobileMenuToggle && overlay) {
        const mobileMenuClose = mobileMenu.querySelector(".mobileMenu__close");

        buildMobileMenu(mobileMenu);

        mobileMenuToggle.addEventListener("click", function () {
            showMobileMenu(mobileMenu, overlay, mobileMenuToggle);
        })

        mobileMenuClose.addEventListener("click", function () {
            hideMobileMenu(mobileMenu, overlay, mobileMenuToggle);
        });

        overlay.addEventListener("click", function () {
            hideMobileMenu(mobileMenu, overlay, mobileMenuToggle);
            for (let filterbox of document.querySelectorAll('.filterBox')) {
                closeFilterBox(filterbox);
            }
        });
    }


    initSearchBar();

    initTrustbars();

    setMenuHeight();

    setMenuHeightContent();

    initFilter();

    initActionClicks();

    initFormSubmitClicks();

    initFormSubmitChanges();

    focusInputGroups();

    trimInputValues();

    initInputBody();

    initPasswordFields();

    initPhoneCountryFields();

    stickyHeader(stickyHeaderArea);

    if (window.outerWidth < breakPointSM) {
        for (let parent of document.querySelectorAll('.navgroup > ul > li')) {
            getChildrenClassForNestedItems(parent);
        }
    }

    for (let parent of document.querySelectorAll('.megaMenu > ul > li')) {
        getChildrenClassForNestedItemsMain(parent);
    }

    for (let parent of document.querySelectorAll('.megaMenu__navigation > ul > li')) {
        getChildrenClassForNestedItems(parent);
    }

    triggerFavoritesBtns();

    for (let video of document.querySelectorAll('video.videoplaybutton')){
        generateVideoplayButton(video);
    }

    for (let video of document.querySelectorAll('video')){
        setSrcVideos(video);
    }


    if (sendItemToBasketByJS) {
        triggerBasketBtns();
    }

    if (updateBasketByJS) {
        triggerUpdateBasketView();
    }

    triggerModal();
    excludeModal();
    triggerConfirmModalLink();

    initQuantitySpinners();

    initToggleSlide();

    initFlashmessages();

    toggleMaxHeight();

    function applyHoverIntent(selector) {
        for (let el of document.querySelectorAll(selector)) {
            hoverintent(el);
        }
    }

    function applyTouchNavigation(selector, isLevel1 = false) {
        for (let el of document.querySelectorAll(selector)) {
            el.addEventListener("click", function (e) {
                let li = el.closest("li");

                // Prüfen, ob das Element eine untergeordnete Navigation hat
                if (!li.classList.contains("hasChildren") && !isLevel1) {
                    return; // Falls kein "hasChildren", dann keine weitere Aktion
                }

                if (isLevel1) {
                    // Nur ein Level 1 Menü darf aktiv sein
                    closeAllLevel1();
                } else {
                    // Nur Geschwister auf derselben Ebene schließen
                    removeSiblingsHoverintent(li);
                }

                if (!li.classList.contains("hoverintent")) {
                    li.classList.add("hoverintent");
                    e.preventDefault(); // Verhindert den direkten Seitenwechsel
                } else {
                    li.classList.remove("hoverintent");
                }
            });
        }
    }

// Entfernt die Klasse 'hoverintent' von allen Level 1 Menüs
    function closeAllLevel1() {
        for (let el of document.querySelectorAll('.js-mainNavigation > ul > li')) {
            el.classList.remove("hoverintent");
        }
    }

// Entfernt 'hoverintent' nur von Geschwister-Elementen
    function removeSiblingsHoverintent(element) {
        let parent = element.parentElement; // UL oder Navigationselement
        if (!parent) return;

        for (let sibling of parent.children) {
            if (sibling !== element) {
                sibling.classList.remove("hoverintent");
            }
        }
    }

    if (IsTouchDevice) {
        applyTouchNavigation('.js-mainNavigation > ul > li > a', true); // Level 1
        applyTouchNavigation('.js-mainNavigation > ul > li.hasChildren > ul > li > a'); // Level 2 mit hasChildren
        applyTouchNavigation('.js-mainNavigation > ul > li.hasChildren > ul > li.hasChildren > ul > li > a'); // Level 3 mit hasChildren

        applyTouchNavigation('.js-megamenu > ul > li.hasChildren > a'); // Nur Menüpunkte mit Unterpunkten
        applyTouchNavigation('.js-megamenu_2 > ul > li.hasChildren > a'); // Nur Menüpunkte mit Unterpunkten
    } else {
        applyHoverIntent('.itembox, .iconbarItem');
        applyHoverIntent('.js-mainNavigation > ul > li');
        applyHoverIntent('.js-megamenu > ul > li');
        applyHoverIntent('.js-megamenu_2 > ul > li');
    }



    initImageGalleryReference();

    initSlideshows();

    initScrollbars();

    initSlidecontents();



    initRecaptchaForms();

    initItemlistSlider(document.querySelectorAll('.itemboxList.swiper'),{
        breakpointItems: {
            xs: 1.7,
            sm: 3,
            md: 4,
            lg: 4,
            xlg: 5
        }
    });

    initCustomSelects();


    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-toggle="tooltip"]'));
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
       return new Tooltip(tooltipTriggerEl);
    });

    initSearchItems(document.querySelectorAll('.js-searchInput'));

    let offsetTopJumpmarks = 0;
    if (stickyHeaderArea) {
        offsetTopJumpmarks = stickyHeaderArea.clientHeight;
    }

    if (typeof GLOBALS!== "undefined") {
        if(GLOBALS.offsetTopJumpmarks){
            offsetTopJumpmarks += GLOBALS.offsetTopJumpmarks;
        }
    }

    initJumpmarks(offsetTopJumpmarks);

    for (let item of document.querySelectorAll(".js-openGallery")) {
        item.addEventListener('click', function (event) {
            openFullscreenGallery(item);
        });
    }

    let offsetTopStickyBox = 30;
    if (stickyHeaderArea) {
        offsetTopStickyBox += stickyHeaderArea.clientHeight;
    }

    for (let pinBox of document.querySelectorAll(".js-stickyBox")) {
        stickyBox(pinBox, {
            offsetTop: offsetTopStickyBox
        });
    }
    lottieLoader();

    initItemlistToggle();
    initPriceListGraduated();

    initAjaxCollectionFilter();

    initDatepicker();

    sortAllSelectOptions();

    initImageGallery();
    testimonialSwiper();

    collectionNewsSwiper();

    for (let el of document.querySelectorAll("[data-count-up]")) {
        countUp(el);
    }

    var loginBox = document.querySelector('.loginBox');

    if(loginBox) {
        startAnimationAfterDelay(loginBox, 100);
    }

    initSparrechner();

    itemcardTabs();

    megaMenuMain.addEventListener('mouseenter', () => {
        setMenuHeight();
    });

    megaMenuMain.addEventListener('mouseleave', () => {
        resetMenuHeight();
    });

    megaMenuMainContent.addEventListener('mouseenter', () => {
        setMenuHeightContent();
    });

    megaMenuMainContent.addEventListener('mouseleave', () => {
        resetMenuHeightContent();
    });

    initVehicleSearchForm();

    if(totalWeightInput || totalWeightInput2 ||trailerWeightInput2) {
        calculateDValue();
        calculateTrailerLoad();

        calculateTotalWeight();
    }

    initContentBoxSwiper();

    handleAsideElement('referenceInterferer', 'js-addToCookieButton', 'hideAsideElement', 7);

    function toggleClassAutomatically() {
        if (sessionStorage.getItem("toggleTriggered")) {
            return;
        }

        sessionStorage.setItem("toggleTriggered", "true");

        setTimeout(() => {
            const element = document.getElementById("referenceInterferer");
            if (element) {
                element.classList.add("active");
            }

            setTimeout(() => {
                if (element) {
                    element.classList.remove("active");
                }
            }, 4000);
        }, 10000);
    }


    toggleClassAutomatically();
});

